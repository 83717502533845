body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: -ms-flexbox;
  display: flex !important;
  opacity: 1;
}

#root {
  height: 100%;
}
